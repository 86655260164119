// var app = app || {};

app = {

  isMobile: null,
  
  init: function() {
   
    //this.logos.init();
    
    // scroll to top
    // http://iamdustan.com/smoothscroll/
    // document.querySelector('.js-scroll-to-top').addEventListener('click', function(e) {
    //   e.preventDefault();
    //   document.querySelector('header').scrollIntoView({ behavior: 'smooth' });
    // });

  },
  
  logos: {
    init: function() {
      
      // show
      var $carousel = $('.logos').removeClass('is-hidden');
      
      // trigger redraw for transition
      $carousel[0].offsetHeight;
      
      // init carousel
      $carousel.flickity({
        // options
        //cellAlign: 'left',
        contain: true,
        autoPlay: 5000,
        wrapAround: true,
        prevNextButtons: true,
        pageDots: false
      });

    }
  }
};


// document ready function
$(function() {  
  
  /*!
   * IE10 viewport hack for Surface/desktop Windows 8 bug
   * Copyright 2014-2015 Twitter, Inc.
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
   */

  // See the Getting Started docs for more information:
  // http://getbootstrap.com/getting-started/#support-ie10-width

  'use strict';

  if (navigator.userAgent.match(/IEMobile\/10\.0/)) {
    var msViewportStyle = document.createElement('style')
        msViewportStyle.appendChild(
          document.createTextNode(
            '@-ms-viewport{width:auto!important}'
          )
        )
    document.querySelector('head').appendChild(msViewportStyle)
  }

  // init the app
  app.init();  

});



